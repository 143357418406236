<template>
  <div>
    <!-- input search -->
    <div class="d-flex">
      <b-col
        md="3"
      >
        <b-form-group
          class="print"
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          class="print"
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="Parcour"
            label="title"
            :options="parcours"
            @input="getAbsences"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          class="print"
          label="Groups"
        >
          <v-select
            v-model="group"
            :clearable="false"
            placeholder="Group"
            label="group_code"
            :options="groups"
            @input="getAbsences"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          class="print"
          label="Matiére"
        >
          <v-select
            v-model="subject"
            :clearable="false"
            placeholder="Matiére"
            label="label"
            :options="subjects"
            @input="getAbsences"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->

    </div>
    <div class="d-flex ">
      <b-col md="3">
        <b-form-group class="print">
          <b-button
            variant="gradient-warning"
            class="ml-1 d-inline-block"
            @click="imprimer"
          >
            <feather-icon icon="PrinterIcon" />
            <span class="ml-1">Imprimer list d'absences</span>
          </b-button>
        </b-form-group>
      </b-col>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="absences"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="print"
        >
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-ediit-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1 text-success"
              size="16"
              @click="generateReport(props.row)"
            />
            <b-tooltip
              title="Imprimer préinscription"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-ediit-icon`"
            />
          </span>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Attestation"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
          >
            <section slot="pdf-content">
              <html
                lang="en"
                dir="ltr"
              >

                <body style="margin:1cm;background-color:white;color:black">

                  <div style="display:flex; justify-content:space-between ;align-items:center">
                    <div>
                      <img
                        src="/logo-mit.png"
                        alt="mit"
                        width="200px"
                        height="auto"
                      >
                    </div>
                    <div>
                      <h5
                        style="color: black;
                text-align: center;
                font-weight: bold"
                      >
                        Ecole polytechnique mediterraneenne  <br> privée de tunis
                      </h5>
                    </div>
                  </div>
                  <div>
                    <hr>
                    <h5
                      style="margin-top: 20px;
                        font-size: 20px;
                        line-height: 45px;
                        color: black;
                        text-align: center;
                        font-weight: bold"
                    > ATTESTATION DE PRE-INSCRIPTION
                    </h5>

                    <h5
                      style="font-size: 20px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
                    >Année Universitaire :
                    </h5>
                    <p style="font-size: 15px; margin-top: 30px; color:black">
                      Le Directeur de l'École Supérieure Méditerranéenne Privée des Sciences Informatiques, Économiques et de

                      Gestion de Nabeul soussigné, certifie que :

                    </p>
                    <p style="font-size: 18px; margin-top: 10px; text-align: center; font-weight: bold; ">
                      {{ currentStudent.student_username }}
                    </p>

                    <p style="font-size: 15px; margin-top: 10px;"> Titulaire de la C.I .N (Passeport pour les étrangers) n° : {{ currentStudent.student_cin }}
                    </p>
                    <p style="font-size: 15px; margin-top: 10px;"> Né le : {{ currentStudent.student_date }} à {{ currentStudent.student_place }}
                    </p><p style="font-size: 15px; margin-top: 10px;"> Est inscrit en :
                    </p>
                    <table
                      style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
                    >
                      <tr>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Parcour
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Filiére
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          niveau
                        </th>
                      </tr>
                      <tr>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.parcour_name }}
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.sector }}

                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.level }}
                        </th>
                      </tr>
                    </table>
                    <p style="font-size: 15px; margin-top: 20px;"> En qualit&eacute; d'&eacute;tudiant qui b&eacute;n&eacute;ficiera d'une inscription r&eacute;guli&egrave;re pour l'ann&eacute;e universitaire {{ new Date().getFullYear() }}/{{ new Date().getFullYear()+1 }}.
                    </p>
                    <br>
                    <br>
                    <br>
                    <p style="font-size: 15px; margin-top: 10px;"> Cette attestation est d&eacute;livr&eacute;e &agrave; l'int&eacute;ress&eacute;e pour servir et valoir ce que de droit.
                    </p>
                    <br><br>

                    <div style="text-align: right; margin-right: 100px;">
                      <h6 style="margin-right: 10px; color:black;">
                        Le Directeur
                      </h6>
                      <h6 style="margin-right: 10px; color:black;">
                        Dr.MAALEJ Rabii
                      </h6>
                    </div>

                  </div>
                  <br><br><br><br>
                  <br><br><br><br><br>
                  <br><br>
                  <br><br>
                  <div class="footer-style"> N.B : il ne peut être délivré qu'une seule attestation. </div>
                </body>
              </html>
            </section>
          </vue-html2pdf>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect,
  BSpinner, BTooltip, BCol, BButton,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    BSpinner,
    BTooltip,
    BButton,
    vSelect,
    BCol,
    VueHtml2pdf,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      level: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'Nom et prénom',
          field: 'student',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nom',
          },
        },
        {
          label: 'Présence',
          field: 'presence',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher presence',
          },
        },

        {
          label: 'Date',
          field: 'date',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Heure',
          field: 'time',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Seance',
          field: 'subject',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ',
          },
        },
        {
          label: 'Date Création',
          field: 'created_at',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ',
          },
        },

      ],
      subjects: [],
      absences: [],
      absence: {},
      subject: {},
      parcours: [],
      groups: [],
      group: {},
      parcour: {},
      allGroups: [],
      currentStudent: {},
      searchTerm: '',
      currentAcademicYear: {},

    }
  },

  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    groupes() {
      // eslint-disable-next-line eqeqeq
      return this.groups.filter(item => item.parcour.id == this.absence.parcour)
      // return this.groups.filter(item => item.parcours.id == this.inscrit.parcour.id)
    },
  },
  created() {
    this.getParcours()
    this.getAcademicYears()
    this.getAbsences()
    this.getGroups()
    this.getSubjects()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    imprimer() {
      window.print()
    },
    generateReport(student) {
      // console.log('id user', id)
      this.currentStudent = student
      // console.log('student', this.currentStudent)
      this.$refs.html2Pdf.generatePdf()
    },
    setGroups() {
      // console.log('test filter---', this.allGroups)
      this.absence.group = ''
      this.groups = this.allGroups.filter(item => item.parcour.id === this.absence.parcour)
      // console.log('allGroups', this.groups)
    },
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getSubjects() {
      const response = await axios.get('/api/subjects/')
      this.subjects = response.data
    },
    async getGroups() {
      this.load = 'true'
      const response = await axios.get('/api/groups/')
      this.allGroups = response.data
      this.groups = response.data
      this.load = 'false'
    },
    async getAbsences() {
      this.load = 'true'
      const response = await axios.get('/api/attendance/', {
        params: {
          academic_year: this.currentAcademicYear.id, subject__parcour: this.parcour.id, group: this.group.id, subject_id: this.subject.id,
        },
      })
      this.absences = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      // const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )}`
    },
    deleteTeachingUnit(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/teaching-units/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getInscriptions()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'unités enseignement supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'unités enseignement Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@media print {

  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .print{
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }
            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
